import React from "react";
import { StaticQuery, graphql, Link } from "gatsby"
import RegionyTemplate from "../../templates/RegionyTemplate";
import RegionyTemplateV2 from "../../templates/RegionyTemplateV2";



const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Ta obsahuje vyzvednutí zesnulého kdekoliv po Liberci a blízkého okolí, převezení do krematoria, samotný žeh a následné uložení do dočasné urny. Doručení urny do vlastních rukou kamkoliv po Liberci. V ceně je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a> a možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.<br/><br/>Pokud jsme pro vašeho blízkého jeli nebo pojedeme k vám domů, bude k celkové částce připočítán poplatek 1.990 Kč (v Praze a Ostravě 3.490 Kč), který pokrývá pohotovostní služby zaměstnanců zajišťujících akutní vyzvednutí.`
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];



const Liberec = ({ data }) => {

  return (
    <StaticQuery
      query={graphql`
        query {
          jumboObrazek: file(relativePath: { eq: "jumboObrazek.png" }) {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }

          jumboObrazekMobile: file(relativePath: { eq: "jumboObrazekMobile.png" }) {
            childImageSharp {
              fixed(width: 460) {
                ...GatsbyImageSharpFixed_noBase64
              }
            }
          }


          velka: file(relativePath: { eq: "velkaLiberec.png" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          mala: file(relativePath: { eq: "malaLiberec.png" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }

          valdstejnska: file(relativePath: { eq: "liberec-valdstejnska.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 440) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      `}
      render={data => ( <>
        <RegionyTemplateV2
          name="Pohřební služba Liberec"
          slug="pohrebni-sluzba-liberec"
          faqData={faqData}

          seoTitle="Pohřební služba Liberec - Empatická & Dostupné ceny (24/7)"
          seoDesc="Nejlépe hodnocená pohřební služba v Liberci (Stráž, Jablonec, Česká Lípa): Pohřeb s obřadem, bez obřadu. Sjednání z domova 24/7. Nízké ceny od 13900 Kč"
          ratingCount={31}

          h1="Pohřební služba Liberec"
          perex={<>Naplánujeme s vámi jedinečný pohřeb v Liberci z bezpečí domova a za předem stanovenou cenu. Přesně takové rozloučení, jaké by si váš blízký přál.<br/><br/>Postaráme se o vše potřebné, abyste se vy a vaše rodina mohli věnovat v těchto těžkých časech především jeden druhému.</>}
          jumboObrazek={data.jumboObrazek.childImageSharp.fixed}
          jumboObrazekMobile={data.jumboObrazekMobile.childImageSharp.fixed}
          reviewDesc="Nejlépe hodnocená pohřební služba v ČR za rok 2024"

          sklonovaneMesto=""

          introH2="V čem jsme jiní než ostatní pohřební služby v Liberci?"
          introText={<>Řešení úmrtí blízkého je možná ta nejtěžší situace v životě člověka: plná stresu, zmatku a smutku. Sami jsme se v minulosti setkali s neempatickým přístupem několika pohřebních služeb. Vypravení pohřbu bylo chladné, neosobní a vždy za neúměrně vysokou cenu. To jsme se rozhodli změnit.<br/><br/>Založili jsme Goodbye s důrazem na osobní přístup a transparentnost – jako pohřební službu, kterou bychom s klidným srdcem nabídli vlastní rodině a přátelům. U nás nemusíte nikam chodit. Celý pohřeb si nezávazně sestavíte přes internet a rovnou se dozvíte konečnou cenu. Náš empatický tým odborníků vám následně pomůže uspořádat jedinečné poslední rozloučení v <Link to="/pohreb-s-obradem/">tradiční</Link> či <Link to="/netradicni-pohreb/">alternativní</Link> podobě pohřbu.</>}

          procesDesc="V klidu vás provedeme celým procesem a zařídíme, co je potřeba. Věříme, že si každý zaslouží důstojné poslední rozloučení. Uděláme vše pro to, abychom vám a vaší rodině pomohli uspořádat v Liberci to pravé."
          procesText1={<>Zajistíme převoz vašeho blízkého do našeho libereckého zázemí. Může se jednat i o <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">akutní vyzvednutí z domu</Link>, nemocnice nebo jiné pohřební služby.</>}
          procesText2={<>Pomůžeme vám naplánovat osobní rozloučení podle vašich přání. Může se jednat o <Link to="/pohreb-s-obradem/">tradiční pohřeb v krematoriu</Link>, <Link to="/kremace-bez-obradu/">kremaci bez obřadu</Link> nebo i <Link to="/netradicni-pohreb/">netradiční variantu</Link>.</>}
          procesText3={<>Zajistíme pietní a důstojnou <Link to="/poradna/kremace-zajimavosti/">kremaci</Link> v našem partnerském krematoriu. Mezi tím za vás vyřešíme veškerou dokumentaci a <Link to="/slovnik-pojmu/umrtni-list/">úmrtní list</Link> na matrice.</>}
          procesText4={<>Urnu si u nás můžete vyzvednout nebo vám ji přivezeme až domů. Popel potom můžete <Link to="/slovnik-pojmu/rozptyl-vsyp-popela/">rozptýlit</Link>, <Link to="/slovnik-pojmu/hrob/">uložit do kolumbária</Link> či například <Link to="/poradna/kremacni-popel/">zasadit strom</Link>. Je to jen na vás.</>}

          kremaceText="Postaráme se o vyzvednutí a následnou kremaci. Urnu vám potom předáme. Forma rozloučení je potom jen na vás."
          kremaceCena="15 990"
          kremaceSluzby={[
            "Sjednání za pár minut přes internet",
            "Vyřízení veškerých dokumentů",
            "Tvorba parte",
          ]}

          pohrebCena="27 490"
          pohrebSluzby={[
            "Řečník, hudba, prezentace fotek",
            "Vyřízení veškerých dokumentů",
            "25-minutový obřad v Liberci"
          ]}

          mestoH2="Máte nějaký speciální požadavek v Liberci?"
          mestoText={<>
            Samozřejmě vám se vším rádi pomůžeme. Zavolejte nám a naši sjednavatelé se s vámi domluví na <Link to="/poradna/smutecni-kytice-pohreb/">výzdobě</Link>, <Link to="/poradna/smutecni-rec-rady-tipy/">řečníkovi</Link>, <Link to="/poradna/pruvodce-tvorbou-smutecniho-oznameni/">parte</Link> nebo čemkoli jiném, co byste si na <Link to="/poradna/jak-naplanovat-pohreb/">pohřbu přáli</Link>.<br/><br/>
            Samozřejmostí je vyzvednutí vašeho blízkého pohřební službou kdekoliv po Liberci a okolí, ať už se zesnulý nachází v Krajské nemocnici Liberec, Domově pro seniory - Františkov nebo kdekoli jinde. V případě <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">úmrtí doma</Link> jsme na místě do několika hodin od prvního telefonátu. Provádíme i akutní vyzvednutí mimo pracovní dobu nebo o víkendech a svátcích. Doba příjezdu se může mírně lišit v návaznosti na konkrétní vzdálenost. Ze zkušenosti jsme ale na místě nejpozději do 3 hodin od zavolání, ať se jedná o Nové či Staré Město, <Link to="/pohrebni-sluzba-liberec/valdstejnska/">Valdštejnskou ulici</Link>, Jeřáb nebo Kristiánov.<br/><br/>
            Pokud využíváte služeb <Link to="/slovnik-pojmu/hospic/">domácího hospice</Link>, je možné se s námi spojit dopředu a domluvit se na postupu. V takovém případě vám v momentě odchodu vašeho blízkého budeme schopni situaci co možná nejvíce ulehčit.<br/><br/>
            Naše pohřební služba Liberec nabízí různé typy pohřbů: pohřeb v několika libereckých <Link to="/obradni-sine/">obřadních síních</Link>, kremaci bez obřadu a netradiční rozloučení v přírodě. Nabízíme také širokou nabídku <Link to="/rakve/">rakví</Link>, <Link to="/poradna/smutecni-kytice-pohreb/">květin</Link>, věnců a dalších <Link to="https://www.prozeny.cz/clanek/pohrebnicke-trendy-miri-do-ceska-obrad-naplanujete-z-pohodli-domova-81281">smutečních předmětů</Link>.
          </>}
          mapLocation={"Liberec"}

          featuresH2="Profesionalita a důstojnost je pro nás na prvním místě"
          featuresText1={<>Ať váš blízký <Link to="/slovnik-pojmu/vyzvednuti-zesnuleho/">zemřel doma</Link>, v Liberecké nemocnici, v Domově pro seniory - Františkov nebo kdekoli jinde, nemusíte nikam chodit. Vše vyřídíte z bezpečí vašeho domova, kde si můžete detaily posledního rozloučení v klidu rozmyslet a probrat je s rodinou.</>}
          featuresText2="Cena, na které se s naší pohřební službou domluvíte, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat, včetně paušálních kilometrů po celém Liberci."
          featuresText3="Chápeme, že úmrtí blízké osoby je nečekaná událost. Proto jsme připraveni ji s vámi řešit v čase, který vyhovuje vám a vaší rodině. Ať už to bude v 10 hodin večer, až půjdou děti spát, nebo během sobotního odpoledne, kdy nebudete rušeni pracovními hovory. Ozvat se nám můžete 24 hodin 7 dní v týdnu."
          
          seVsimPomuzemeText="Úmrtí člena rodiny je velmi náročnou situací pro všechny pozůstalé. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení v Liberci a okolí."
          
          obradniSineH2="Liberecké obřadní síně"
          obradniSine={[
            {
              h3: "Malá obřadní síň krematoria Liberec",
              img: data.mala.childImageSharp.fluid,
              link: "/obradni-sine/mala-obradni-sin-krematorium-liberec/",
              text: <>Malá obřadní síň krematoria Liberec se nachází na adrese U Krematoria 460/7 a obřady se v ní konají každou středu. Celková kapacita síně je zhruba 40 osob, z toho 24 míst je na sezení. Po obou stranách síně jsou obrazovky, na kterých je možné zobrazit během obřadu jednu fotografii s iniciály zesnulého, případně promítnout prezentaci fotek. Obřad trvá cca 20 - 25 minut. Během tohoto času je prostor pro několik reprodukovaných písní a krátkou promluvu <Link to="/poradna/smutecni-rec-rady-tipy/">řečníka</Link>.</>
            },

            {
              h3: "Velká obřadní síň krematoria Liberec",
              img: data.velka.childImageSharp.fluid,
              link: "/obradni-sine/velka-obradni-sin-krematorium-liberec/",
              text: <>Obřady ve Velké obřadní síni se konají každý čtvrtek a pátek mimo svátků. Stejně jako v Malé obřadní síni, jsou i v této nachystány obrazovky, na kterých je možné během obřadu <Link to="/slovnik-pojmu/prezentace-fotek/">promítat fotografie</Link> či video. Obřad trvá cca 20 - 25 minut. Síň je standardně vybavena umělou květinovou výzdobou, 4 věnci a 6 kyticemi. Tato umělá výzdoba může být nahrazena květinami živými.</>
            },
          ]}

          dalsiOdkazy={[
            {
              h4: "Pohřební služba Liberec: Valdštejnská",
              p: "Pokud hledáte pohřební službu v blízkosti ulice Valdštejnská nebo Krajské nemocnice Liberec.",
              link: "/pohrebni-sluzba-liberec/valdstejnska/",
              img: data.valdstejnska.childImageSharp.fluid,
            },
          ]}
        />
      </>)}
    />
  );
}

export default Liberec;


